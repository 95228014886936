import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation, Output, EventEmitter, ViewChildren, QueryList, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject, fromEvent } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { GridBoardsService } from 'app/services/grid-boards.service';
import { cloneDeep, map, forEach, find, isEqual, merge, isEmpty, isString } from 'lodash';
import { AccountsService } from 'app/services/accounts.service';
import { OwnersService } from 'app/services/owners.service';
import { MatPaginator } from '@angular/material/paginator';
import { ScrumboardBoardCardComponent } from './card/card.component';
import * as _ from 'lodash';
import { AuthenticationService } from 'app/services';
import { CrmMenuService } from 'app/services/crm-menu.service';
import { GlobalFuntions } from 'app/_helpers';
import { environment } from 'environments/environment';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';


@Component({
    selector: 'scrumboard-board-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ScrumboardBoardListComponent implements OnInit, AfterViewInit, OnDestroy {

    board: any;
    dialogRef: any;

    @Input() list: any = {};
    body: any;
    loader: boolean;
    currentPageSelected: boolean;
    allPagesSelected: boolean;
    // listQuery: any;
    relatedTo: any;
    id: number;
    currentUser: any;
    previousSearch: any;
    public env = environment;
    @Input() isDragDisabled : boolean = false;
    
    @Input()
    set searchFields(searchFields: any) {
        if (!this.list) {
            this.list = {};
        }
        const query = cloneDeep(this.list.query);
        const options = {
            page: 1,
            limit: 10,
            sort: {
                // updated_at: -1
                created_at: -1
            }
        };
        this.body = {
            query: query,
            options: options,
        };
        if (searchFields) {
            this.body.searchFields = searchFields;
        }

        this.getListData();
        this.currentPageSelected = false;
        this.allPagesSelected = false;
        this.selectAllPages();
    }

    allSelectedData = [];
    @Output() oppenSettings: EventEmitter<any>;
    @Output() selectData: EventEmitter<any>;
    @Output() selectAllData: EventEmitter<any>;
    @Output() openTimeline: EventEmitter<any>;
    @Output() refreshLists: EventEmitter<any>;
    @Output() openProperties: EventEmitter<any>;
    @Output() relatedAccounts: EventEmitter<any>;
    @Output() onRefreshBoard: EventEmitter<any>;
    @Output() viewProperty: EventEmitter<any>;
    @Output() viewMooring: EventEmitter<any>;
    @Output() viewBoat: EventEmitter<any>;
    @Output() listDataTotal: EventEmitter<any>;
    @Output() viewCommercial: EventEmitter<any>;
    @Output() openCommercials: EventEmitter<any>;
    @Output() openConstruction: EventEmitter<any>;
    @Output() openMoorings: EventEmitter<any>;
    @Output() openBoats: EventEmitter<any>;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    // @ViewChildren(FusePerfectScrollbarDirective)
    // private _fusePerfectScrollbarDirectives: ScrumboardBoardCardComponent;

    @ViewChildren(ScrumboardBoardCardComponent) scrumboardBoardCard !: QueryList<ScrumboardBoardCardComponent>;

    @ViewChild(FusePerfectScrollbarDirective)
    listScroll: FusePerfectScrollbarDirective;

    // Private
    private _unsubscribeAll: Subject<any>;
    listData: any;

    /**
     * Constructor
     *
     * @param {ActivatedRoute} _activatedRoute
     * @param {MatDialog} _matDialog
     */
    constructor(
        private route: ActivatedRoute,
        private _gridBoardsService: GridBoardsService,
        private _accountsService: AccountsService,
        private _ownersService: OwnersService,
        private _authenticationService: AuthenticationService,
        private router: Router,
        public _crmMenuService: CrmMenuService,
        public _globalFuntions: GlobalFuntions,
        private cdr: ChangeDetectorRef
    ) {
        this.currentUser = this._authenticationService.currentUserValue;
        // Set the private defaults
        this.openProperties = new EventEmitter();
        this.relatedAccounts = new EventEmitter();
        this.onRefreshBoard = new EventEmitter();
        this.viewProperty = new EventEmitter();
        this.viewMooring = new EventEmitter();
        this.viewBoat = new EventEmitter();
        this.oppenSettings = new EventEmitter();
        this.selectData = new EventEmitter();
        this.selectAllData = new EventEmitter();
        this.openTimeline = new EventEmitter();
        this.refreshLists = new EventEmitter();
        this.listDataTotal = new EventEmitter();
        this.viewCommercial = new EventEmitter();
        this.openCommercials = new EventEmitter();
        this.openConstruction = new EventEmitter();
        this.openMoorings = new EventEmitter();
        this.openBoats = new EventEmitter();
        this._unsubscribeAll = new Subject();
        
        this.id = +this.route.snapshot.paramMap.get('id');
        this.previousSearch = this.route.snapshot.queryParamMap.get('previousSearch');
        fromEvent(window, 'popstate')
            .subscribe((e) => {
                if (this.route.snapshot && this.route.snapshot['_routerState'].url) {
                    this.router.navigate([this.route.snapshot['_routerState'].url], { queryParams: { previousSearch: true } });
                }
            });
        this.route.data
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(v => { this.relatedTo = v.relatedTo; });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

    }

    getListData(): void {

        this.paginator.pageIndex = this.body.options.page - 1;
        this.loader = true;
        if (this.previousSearch === 'true' && !isEmpty(this._gridBoardsService.getGridSearch)) {
            this.body.searchFields = this._gridBoardsService.getGridSearch;
        }
        if (this.relatedTo === 'account') {
            this._gridBoardsService.getAccountsData(this.body)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((data) => {
                    // forEach(data.docs, (doc: any) => {
                    //     if (!isEmpty(doc.assigned_to) && isString(doc.assigned_to)) {
                    //         doc.assigned_to = [doc.assigned_to];
                    //     }
                    // });
                    this.listData = data;
                    this.loader = false;
                    this.listDataTotal.next(data.total);
                    this.getActivitiesCount();
                });
        }
        if (this.relatedTo === 'owner') {
            this._gridBoardsService.getOwnersData(this.body)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((data) => {
                    this.listData = data;
                    this.loader = false;
                    this.listDataTotal.next(data.total);
                    this.getOwnersActivitiesCount();
                });
        }
    }

    getOwnersActivitiesCount(): void {
        const ids = map(this.listData.docs, '_id');
        this._gridBoardsService.ownersCount(ids)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data) => {
                forEach(this.listData.docs, (value: any) => {
                    const findAct: any = find(data, { _id: value._id });
                    value.activities_ids = findAct.activities_ids;
                });
            });
    }

    getActivitiesCount(): void {
        const ids = map(this.listData.docs, '_id');
        this._gridBoardsService.activitiesCount(ids)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data) => {
                forEach(this.listData.docs, (value: any) => {
                    const findAct: any = find(data, { _id: value._id });
                    value.activities_ids = findAct.activities_ids;
                });
            });
    }

    onListNameChanged(newListName): void {
        this.list.name = newListName;
    }

    onCardAdd(newCardName): void {
        if (newCardName === '') {
            return;
        }

        setTimeout(() => {
            this.listScroll.scrollToBottom(0, 400);
        });
    }


    // onDrag(ev: any, query: any): void {
    //     this.listQuery = query;
    // }

    onDrop(ev: CdkDragDrop<any[]>, query: any): void {
        console.log('ev', ev);
        if (ev.previousContainer !== ev.container) {
            const toUpdate = this.getToUpdata(query);
            const account = ev.previousContainer.data[ev.previousIndex];
            if (toUpdate.status) {
                account.status = toUpdate.status;
            }
            if (this.relatedTo === 'account') {
                this._accountsService.update(account)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(() => {
                        this.getListData();
                    });
            }
            if (this.relatedTo === 'owner') {
                this._ownersService.update(account)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(() => {
                        this.getListData();
                    });
            }
            transferArrayItem(
                ev.previousContainer.data,
                ev.container.data,
                ev.previousIndex,
                ev.currentIndex,
              );
        } else {
            moveItemInArray(ev.container.data, ev.previousIndex, ev.currentIndex);
        }
    }

    getToUpdata(query: any): any {
        let mRule = {};
        if (query && query.condition && query.rules && query.rules.length) {
            forEach(query.rules, (rule) => {
                if (!rule.condition) {
                    if ((rule.operator === '=' || rule.operator === 'in')) {
                        mRule[rule.field] = rule.value;
                    }
                } else if (rule.condition) {
                    mRule = merge(this.getToUpdata(rule), mRule);
                }
            });
        }
        return mRule;
    }

    changePage(event: any): void {
        this.currentPageSelected = false;
        this.body.options.page = event.pageIndex + 1;
        this.getListData();
    }

    sorting(sort: any): void {
        this.body.options.sort = sort;
        this.getListData();
    }

    onChangeProbability(probability: any, data: any): void {
        if (probability.value) {
            probability.value = probability.value.toString();
        }
        this.loader = true;
        data.probability = probability;
        if (this.relatedTo === 'account') {
            this._accountsService.update(data)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(() => {
                    this.getListData();
                }, () => this.loader = false);
        }
        if (this.relatedTo === 'owner') {
            this._ownersService.update(data)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(() => {
                    this.getListData();
                }, () => this.loader = false);
        }
    }

    selectCurrentPage(toSelect: boolean): void {
        if (this.scrumboardBoardCard) {
            const cards = this.scrumboardBoardCard.toArray();
            _.forEach(cards, (card: ScrumboardBoardCardComponent) => {
                card.checked = toSelect;
                card.selectCard.next(card.checked);
            });
        }
    }

    selectAllPages(): void {
        this.selectCurrentPage(this.allPagesSelected);
        if (this.allPagesSelected) {
            this.loader = true;
            if (this.relatedTo === 'account') {
                this._gridBoardsService.getAccountsData(this.body, true)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((data) => {
                        this.allSelectedData = data;
                        this.selectAllData.next([this.allPagesSelected, data]);
                        this.loader = false;
                    });
            }
            if (this.relatedTo === 'owner') {
                this._gridBoardsService.getOwnersData(this.body, true)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((data) => {
                        this.allSelectedData = data;
                        this.selectAllData.next([this.allPagesSelected, data]);
                        this.loader = false;
                    });
            }
        } else {
            this.loader = false;
            this.selectAllData.next([this.allPagesSelected, this.allSelectedData]);
        }
    }

    onUpdateCard(event: any): void {
        this.getListData();
    }

    ngAfterViewInit(): void {
        this.scrumboardBoardCard.changes
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => this.selectCurrentPage(this.allPagesSelected));
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
