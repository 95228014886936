import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MlsSharingService {

  constructor(private http: HttpClient) { }

  getData(postData: any, page?: any, perPage?: any, ninId?: any): Observable<any[]> {
    page = page ? page : 1;
    perPage = perPage ? perPage : 10;
    return this.http.post<any[]>(`${environment.yiiUrl}sharing-levels/search?page=${page}&per-page=${perPage}&not_in=${ninId}`, postData, { observe: 'response' as 'body' })
      .pipe(map(data => {
        return data;
      }));
  }

  createData(data: any): Observable<object> {
    return this.http.post(`${environment.yiiUrl}sharing-levels`, data);
  }

  updateData(data: any): Observable<object> {
    return this.http.put(`${environment.yiiUrl}sharing-levels/${data._id}`, data);
  }

  deleteData(_id: any): Observable<any> {
    return this.http.delete(`${environment.yiiUrl}sharing-levels/${_id}`);
  }

  insertSharingRequest(data: any): Observable<any> {
    return this.http.post(`${environment.yiiUrl}sharing-requests?expand=agency_shared_data`, data);
  }

  updateSharingRequest(data: any): Observable<any> {
    return this.http.put(`${environment.yiiUrl}sharing-requests/${data._id}?expand=agency_shared_data`, data);
  }

  updateMultiSharingRequest(data: any): Observable<any> {
    return this.http.post(`${environment.yiiUrl}sharing-requests/update-requests`, data);
  }

  // deleteSharingRequest(data: any): Observable<any> {
  //   return this.http.delete(`${environment.yiiUrl}sharing-requests/${data._id}`);
  // }

  // getIncommingPending(agencyFrom?: any, agencyTo?: any): Observable<any> {
  //   return this.http.get(`${environment.yiiUrl}sharing-requests/incomming-pending?expand=agency_from_data,agency_to_data&agency_from=${agencyFrom}&agency_to=${agencyTo}`,
  //     { observe: 'response' as 'body' });
  // }

  // getOutgoingPending(agencyFrom?: any, agencyTo?: any): Observable<any> {
  //   return this.http.get(`${environment.yiiUrl}sharing-requests/outgoing-pending?expand=agency_from_data,agency_to_data&agency_from=${agencyFrom}&agency_to=${agencyTo}`,
  //     { observe: 'response' as 'body' });
  // }

  // getIncommingApproved(agencyFrom?: any, agencyTo?: any): Observable<any> {
  //   return this.http.get(`${environment.yiiUrl}sharing-requests/incomming-approved?expand=agency_from_data,agency_to_data&agency_from=${agencyFrom}&agency_to=${agencyTo}`,
  //     { observe: 'response' as 'body' });
  // }

  // getOutgoingApproved(agencyFrom?: any, agencyTo?: any): Observable<any> {
  //   return this.http.get(`${environment.yiiUrl}sharing-requests/outgoing-approved?expand=agency_from_data,agency_to_data&agency_from=${agencyFrom}&agency_to=${agencyTo}`,
  //     { observe: 'response' as 'body' });
  // }

  getPublicMls(data: any, page?: any, perPage?: any): Observable<any> {    
    // return this.http.post(`${environment.yiiUrl}sharing-requests/public-mls-count?page=${page}&per-page=${perPage}&expand=f_country,f_city,incoming_properties,incoming_commercial,incoming_request,outgoing_properties,outgoing_commercial,outgoing_request`,
    // return this.http.post(`${environment.yiiUrl}sharing-requests/public-mls?page=${page}&per-page=${perPage}&expand=f_country,f_city,incoming_request,outgoing_request`,
    return this.http.post<any[]>(`${environment.apiUrl}sharing_requests/public-mls-count?page=${page}&per-page=${perPage}`,
    data,
    { observe: 'response' as 'body' });
  }

  getNewRequests(): Observable<any> {
    return this.http.get(`${environment.yiiUrl}sharing-requests/new-requests?expand=agency_from_data,agency_to_data&pagination=false`, { observe: 'response' as 'body' });
  }

  agencyToCompany(agencyId: any): Observable<any> {
    return this.http.get(`${environment.yiiUrl}sharing-requests/agency-to-company?agency_id=${agencyId}`);
  }

}
