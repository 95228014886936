import { Component, OnInit, ViewEncapsulation, Inject, ChangeDetectorRef, AfterViewChecked, OnDestroy, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DropdownsService, AuthenticationService } from 'app/services';
import { TranslateService } from '@ngx-translate/core';
import { merge, Subject } from 'rxjs';
import { startWith, switchMap, tap, distinctUntilChanged, takeUntil, debounceTime } from 'rxjs/operators';
import { FuseUtils } from '@fuse/utils';
import { isEmpty, map, orderBy } from 'lodash';
import { CrmMenuService } from 'app/services/crm-menu.service';
import { GetCommercialReferencePipe } from 'app/pipes/get-commercial-reference.pipe';

@Component({
    selector: 'accounts-advance-search',
    templateUrl: './advance-search.component.html',
    styleUrls: ['./advance-search.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [GetCommercialReferencePipe]
})
export class AdvanceSearchComponent implements OnInit, AfterViewChecked, OnDestroy {

    searchForm: FormGroup;
    searchPropertyTypes$ = new Subject<string>();
    propertyTypes: any;
    searchPropertySubTypes$ = new Subject<string>();
    searchLocationGroups$ = new Subject<string>();
    propertySubTypes: any;
    currentLang: string;
    propTypes: any;
    ParPropTypes: any;
    buildingStyle: any;
    locationsCtrl = new FormControl();
    filteredLocations: any;
    locations: string[] = [];

    languages: any;

    urbanisationSearch: FormControl = new FormControl('');
    urbanisations: any;
    searching: boolean;

    currentAgency: any;
    searchProperties$ = new Subject<string>();
    properties: any = [];
    searchCommercials$ = new Subject<string>();
    commercials: any = [];
    private _unsubscribeAll: Subject<any>;
    searchString: string;
    locationGroups: any;
    searchCountries$ = new Subject<string>();
    searchRegions$ = new Subject<string>();
    searchProvinces$ = new Subject<string>();
    searchCities$ = new Subject<string>();
    searchProject$ = new Subject<string>();
    constructions = [] as any;
    isConstructionModule: boolean = false;
    cities = [] as any;
    provinces = [] as any;
    regions: any;
    countries = [] as any;
    transaction_types : any = [];
    purposeData: { key: string; value: any; }[];
    langReload = new EventEmitter<any>();

    constructor(
        private _authenticationService: AuthenticationService,
        public matDialogRef: MatDialogRef<AdvanceSearchComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private _dropdownsService: DropdownsService,
        private _translateService: TranslateService,
        private cdRef: ChangeDetectorRef,
        public _crmMenuService: CrmMenuService,
        private cp_reference: GetCommercialReferencePipe
    ) {
        this.currentLang = this._translateService.currentLang === 'es' ? 'es_AR' : this._translateService.currentLang;
        this.currentAgency = this._authenticationService.currentAgencyValue;
        this.searchForm = this.createEventForm();
        if (this._data) {
            this.searchForm.patchValue(this._data);
        }
        this._unsubscribeAll = new Subject();
        this.transaction_types =  [
            { value: 'Short term rental', label: 'Short term rental' },
            { value: 'Long term rental', label: 'Long term rental' },
            { value: 'Buy', label: 'Buy' },
            { value: 'Nude purchase', label: 'Nude purchase' },
            { value: 'Fractional', label: 'Fractional' },
            { value: 'Rent to buy', label: 'Rent to buy' }
        ];
        this.isConstructionModule = this._crmMenuService.isConstructionModule;
    }

    ngOnInit(): void {
        if(this._crmMenuService.isCommercialModule){
            this.transaction_types =  [
                { value: 'st_rent', label: 'Short term rental' },
                { value: 'lt_rent', label: 'Long term rental' },
                { value: 'sale', label: 'Buy' },
                { value: 'equitable_title_sale', label: 'Nude purchase' },
                { value: 'fractional', label: 'Fractional' },
                { value: 'rent_to_buy', label: 'Rent to buy' }
            ];
        }
        this._translateService.onLangChange.subscribe(() => {
            this.currentLang = this._translateService.currentLang === 'es' ? 'es_AR' : this._translateService.currentLang;
            
            const locationGroups = map(this.locationGroups, (locationGroup) => {
                locationGroup.label = locationGroup.value[this.currentLang];
                return locationGroup;
            });
            this.locationGroups = locationGroups;
            this.langReload.next(true);
        });

        this.langReload.pipe(startWith('')).subscribe(() => {
            this.getPurposeData();
        });

        this._dropdownsService.getAccountsBuildings().subscribe((data: any) => {
            const buildingStyle = map(data.body, (building) => {
                building.label = building.value[this.currentLang];
                return building;
            });
            this.buildingStyle = buildingStyle;
        });

        this.searchCountries$
        .pipe(
          startWith(''),
          takeUntil(this._unsubscribeAll),
          switchMap((search: string) => {
            return this._dropdownsService.getCountries(search);
          }),
        )
        .subscribe((data: any) => {
          const countries = map(data.docs, (doc) => {
            doc.label = doc.value[this.currentLang];
            return doc;
          });
          this.countries = countries;
        });

        this.searchRegions$
        .pipe(
          startWith(''),
          takeUntil(this._unsubscribeAll),
          switchMap((search: string) => {
            return this._dropdownsService.getRegions(search, this.searchForm.get('country.$in').value);
          }),
        )
        .subscribe((data: any) => {
          const regionsVal = map(data.docs, (doc) => {
            doc.label = doc.value[this.currentLang];
            return doc;
          });
          if (!isEmpty(this.searchForm.get('country.$in').value)) {
            this.regions = regionsVal;
          }
  
        });

        this.searchProvinces$
        .pipe(
          startWith(''),
          takeUntil(this._unsubscribeAll),
          switchMap((search: string) => {
            return this._dropdownsService.getProvinces(search, false, this.searchForm.get('region').value);
          }),
        )
        .subscribe((data: any) => {
          const provinces = map(data.docs, (doc) => {
            doc.label = doc.value[this.currentLang];
            return doc;
          });
          if (!isEmpty(this.searchForm.get('region').value)) {
            this.provinces = provinces;
          }
        });

        
        this.searchCities$
        .pipe(
        startWith(''),
        takeUntil(this._unsubscribeAll),
        switchMap((search: string) => {
            return this._dropdownsService.getCities(search, false, false, this.searchForm.get('province.$in').value);
        }),
        )
        .subscribe((data: any) => {
        const cities = map(data.docs, (doc) => {
            doc.label = doc.value[this.currentLang];
            return doc;
        });
        if (!isEmpty(this.searchForm.get('province.$in').value)) {
            this.cities = cities;
        }
        });

        this.searchPropertyTypes$
            .pipe(
                startWith(''),
                takeUntil(this._unsubscribeAll),
                switchMap((search: string) => {
                    if(this._crmMenuService.isCommercialModule){
                        return this._dropdownsService.getCommercialTypes(search);
                    }
                    return this._dropdownsService.getPropertyTypes(search);
                }),
            )
            .subscribe((data: any) => {
                const propertyTypes = map(data, (type) => {
                    type.label = type.value[this.currentLang];
                    return type;
                });
                this.propertyTypes = propertyTypes;
            });

        this.searchPropertySubTypes$
            .pipe(
                startWith(''),
                takeUntil(this._unsubscribeAll),
                switchMap((search: string) => {
                    return this._dropdownsService.getPropertySubTypes(this.searchForm.get('property_types.$in').value, search);
                }),
            )
            .subscribe((data: any) => {
                const propertySubTypes = map(data, (type) => {
                    type.label = type.value[this.currentLang];
                    return type;
                });
                this.propertySubTypes = propertySubTypes;
            });

        this.searchLocationGroups$
            .pipe(
                startWith(''),
                distinctUntilChanged(),
                switchMap((search: string) => {
                    this.searchString = search;
                    return this._dropdownsService.getLocationsGroups();
                }),
            )
            .subscribe((data: any) => {
                const locationGroups = map(data, (locationGroup) => {
                    locationGroup.label = locationGroup.value[this.currentLang];
                    return locationGroup;
                });
                this.locationGroups = locationGroups;

                if (this.search !== null) {
                    this.locationGroups = FuseUtils.filterArrayByString(this.locationGroups, this.searchString.toLowerCase());
                }
                this.searchString = '';
            });

        this.locationsCtrl.valueChanges.pipe(
            startWith(''),
            switchMap(() => {
                return this._dropdownsService.getLocations(this.locationsCtrl.value, 1);
            }),
        ).subscribe((data: any) => {
            this.filteredLocations = data;
        });
        this._dropdownsService.getLanguages()
            .subscribe((data: any) => {
                this.languages = data.body;
            });
        this.urbanisationSearch.valueChanges
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap(() => {
                    return this._dropdownsService.getUrbanisation(this.urbanisationSearch.value);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.urbanisations = data.docs;
            });
        this.searchProject$
        .pipe(
            debounceTime(200),
            distinctUntilChanged(),
            takeUntil(this._unsubscribeAll),
            switchMap((search: string) => {
                return this._dropdownsService.getProject(search);
            })
        )
        .subscribe((data: any) => {
            const constructions = map(data.body, (body) => {
                body.label = '';
                if (body?.reference) body.label += body.reference;
                if (body?.user_reference) body.label += "-" + body.user_reference;
                if (body?.agency_reference) body.label += "-" + body.agency_reference;
                return body;
            });
            this.constructions = constructions;
        });
        merge(
            this.searchProperties$,
            this.searchForm.get('properties_interested').valueChanges
        )
            .pipe(
                startWith(''),
                distinctUntilChanged(),
                takeUntil(this._unsubscribeAll),
                switchMap((search: any) => {
                    const pi = this.searchForm.get('properties_interested').value;
                    if (typeof search !== 'string') {
                        search = '';
                    }
                    return this._dropdownsService.getProperty(
                        search,
                        this.searchForm.get('properties_interested').value
                    );
                })
            )
            .subscribe((data: any) => {
                const properties = map(data.body, (property) => {
                    if (
                        this.currentAgency.pdf_external_ref ===
                        'external_reference' &&
                        property.agency_reference
                    ) {
                        property.label = property.agency_reference;
                    } else if (
                        this.currentAgency.pdf_external_ref ===
                        'agency_reference' &&
                        property.external_reference
                    ) {
                        property.label = property.external_reference;
                    } else if (
                        this.currentAgency.pdf_external_ref === 'reference'
                    ) {
                        property.label = property.reference;
                    } else {
                        property.label = property.reference;
                    }
                    if (property.hasOwnProperty('type_one')) {
                        property.label =
                            property.label + '-' + property.type_one;
                    }
                    if (property.hasOwnProperty('location')) {
                        property.label =
                            property.label + '-' + property.location;
                    }
                    return property;
                });
                this.properties = properties;
            });  
            
        merge(
            this.searchCommercials$,
            this.searchForm.get('commercials_interested').valueChanges
        )
            .pipe(
                startWith(''),
                distinctUntilChanged(),
                takeUntil(this._unsubscribeAll),
                switchMap((search: string) => {
                    const filterQuery = {} as any;
                    if (!search) {
                        search = '';
                    }
                    search = search;
                    const queryOr = [];
                    queryOr.push({ reference: Number(search) });
                    queryOr.push({
                        external_reference: {
                            $regex: '.*' + search + '.*',
                            $options: 'i',
                        },
                    });
                    queryOr.push({
                        other_reference: {
                            $regex: '.*' + search + '.*',
                            $options: 'i',
                        },
                    });
                    filterQuery.$or = queryOr;
                    return this._dropdownsService.getCommercialProperties(
                        filterQuery
                    );
                })
            )
            .subscribe((data: any) => {
                const commercials = map(data.body, (property) => {
                    property.label = this.cp_reference.transform(property)
                    if (property.hasOwnProperty('property_type_one') && property?.property_type_one?.value) {
                        property.label = property.label + '-' + property?.property_type_one?.value[this.currentLang];
                    }
                    if (property.hasOwnProperty('property_location') && property?.property_location?.value) {
                        property.label = property.label + '-' + property?.property_location?.value[this.currentLang];
                    }
                    return property;
                });
                this.commercials = commercials;
            });
        this.searchProject$.next('');
    }

    getPurposeData() {
        this.purposeData = [
            {
                key: 'Primary residence',
                value: this._translateService.instant('Primary residence'),
            },
            {
                key: 'Secondary residence',
                value: this._translateService.instant('Secondary residence'),
            },
            {
                key: 'Invesrment',
                value: this._translateService.instant('Invesrment'),
            },
            {
                key: 'investment_sales',
                value: this._translateService.instant('investment_sales'),
            },
            {
                key: 'Construction',
                value: this._translateService.instant('Construction'),
            },
        ];
        this.purposeData = orderBy(this.purposeData, ['value'], ['asc']);
    }

    createEventForm(): FormGroup {
        return new FormGroup({
            transaction_types: this._formBuilder.group({
                $in: new FormControl([])
            }),
            purpose: this._formBuilder.group({
                $in: new FormControl([])
            }),
            property_types: this._formBuilder.group({
                $in: new FormControl([])
            }),
            sub_types: this._formBuilder.group({
                $in: new FormControl([])
            }),
            country: this._formBuilder.group({
                $in: []
            }),
            province: this._formBuilder.group({
                $in: []
            }),
            city: this._formBuilder.group({
                $in: []
            }),
            region: new FormControl([]),
            locations_group: this._formBuilder.group({
                $in: new FormControl([])
            }),
            locations: this._formBuilder.group({
                $in: new FormControl([])
            }),
            language: this._formBuilder.group({
                $in: new FormControl([])
            }),
            assigned_to: this._formBuilder.group({
                $in: new FormControl([])
            }),
            // properties_interested
            properties_interested: this._formBuilder.group({
                $in: new FormControl([])
            }),
            // commercials_interested
            commercials_interested: this._formBuilder.group({
                $in: new FormControl([])
            }),
            // constructions_interested / project_interested
            constructions_interested: this._formBuilder.group({
                $in: new FormControl([])
            }),
            min_beds: new FormControl(),
            min_baths: new FormControl(),
            buy_price_low: new FormControl(),
            buy_price_high: new FormControl(),
            cash_buyer: new FormControl(),
            filter_projects: new FormControl(),
            created_date_from: new FormControl(),
            created_date_to: new FormControl(),
            urbanization: new FormControl(),
            short_term_Rent_price_low: new FormControl(),
            short_term_Rent_price_high: new FormControl(),
            long_term_Rent_price_low: new FormControl(),
            long_term_Rent_price_high: new FormControl(),
            gdpr_status: new FormControl(),
            status: new FormControl(),
            residential_newsletter: new FormControl(),
            commercial_newsletter: new FormControl(),
            building_style: new FormControl(),
            last_updated_from: new FormControl(),
            last_updated_to:  new FormControl(),
            inactive_message: new FormControl(),
        });
    }

    onRemove(dataArray: any, data: string): void {
        const index = dataArray.indexOf(data);
        if (index >= 0) {
            dataArray.splice(index, 1);
        }
    }

    onSelected(dataArray: any, event: any): void {
        if (dataArray.indexOf(event.option.value) < 0) {
            dataArray.push(event.option.value);
        }
    }

    search(): void {
        this.searchForm.get('locations.$in').setValue(map(this.locations, 'key'));
        this.matDialogRef.close(this.searchForm);
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
