import { Component, OnInit, ViewEncapsulation, Input, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountsService } from 'app/services/accounts.service';
import { fuseAnimations } from '@fuse/animations';
import { Subject } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { Accounts } from 'app/models/accounts';
import { TranslateService } from '@ngx-translate/core';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { CrmMenuService } from 'app/services/crm-menu.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlobalFuntions } from 'app/_helpers/global.funtions';
import { AuthenticationService } from 'app/services';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { takeUntil, filter, map, startWith } from 'rxjs/operators';
import { isArray, uniq, toArray } from 'lodash';


@Component({
    selector: 'accounts-create',
    templateUrl: './accounts-create.component.html',
    styleUrls: ['./accounts-create.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class AccountsCreateComponent implements OnInit, AfterViewChecked {
    form: UntypedFormGroup;
    account: Accounts;
    id: string;
    private _unsubscribeAll: Subject<any>;
    viewRecord: boolean;
    CurrentAccount: any;
    message: string;
    subRouts: { label: string; path: string; index: number; }[];
    relatedCompanies: UntypedFormArray;
    customSettings: UntypedFormArray;
    financialAccounts: UntypedFormArray;
    title = '';
    rpaId: any;
    rpcId: any;
    searchDialogRef: any;
    partners: UntypedFormArray;
    previousUrl = '/master-data/accounts';
    currentUser: any;
    currentOffice: any;
    currentAgency: any;
    profileLocations: any;

    probabilities = [
        { value: '1', name: '0-20%', colour: 'blue' },
        { value: '2', name: '21-50%', colour: 'green' },
        { value: '3', name: '51-70%', colour: 'yellow' },
        { value: '4', name: '71-99%', colour: 'red' },
        { value: '5', name: '100%', colour: 'white' }
    ];

    constructor(
        private _accountsService: AccountsService,
        private _formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private cdref: ChangeDetectorRef,
        private _authenticationService: AuthenticationService,
        private _snackBar: MatSnackBar,
        private _translateService: TranslateService,
        private _fuseSidebarService: FuseSidebarService,
        public _crmMenuService: CrmMenuService,
        private _globalFunction: GlobalFuntions,
        private mediaObserver: MediaObserver,
    ) {
        this.currentUser = this._authenticationService.currentUserValue;
        this.currentOffice = this._authenticationService.currentOfficeValue;
        this.currentAgency = this._authenticationService.currentAgencyValue;
        this._unsubscribeAll = new Subject();

        if (window.history.state) {
            if (window.history.state.route) {
                let previousUrl = window.history.state.route;
                if (previousUrl.indexOf('?') > -1) {
                    previousUrl = previousUrl.substring(0, previousUrl.indexOf('?'));
                }
                this.previousUrl = previousUrl;
            }
            else {
                this.previousUrl = '/master-data/accounts';
            }
        }


        this.id = this.route.snapshot.paramMap.get('id');
        this.subRouts = [{
            label: 'id',
            path: './id',
            index: 0
        }, {
            label: 'contact',
            path: './contact',
            index: 1
        }, {
            label: 'profile',
            path: './profile',
            index: 2
        }, {
            label: 'mooring-profile',
            path: './mooring-profile',
            index: 3
        },{
            label: 'boat-profile',
            path: './boat-profile',
            index: 4
        }, {
            label: 'profile-non-residential',
            path: './profile-non-residential',
            index: 5
        }, {
            label: 'notes',
            path: './notes',
            index: 6
        }, {
            label: 'documents',
            path: './documents',
            index: 7
        }, {
            label: 'agency',
            path: './agency',
            index: 8
        }, {
            label: 'financials',
            path: './financials',
            index: 9
        }, {
            label: 'history',
            path: './history',
            index: 10
        }, {
            label: 'audit',
            path: './audit',
            index: 11
        }];
        if (!this._crmMenuService.isPropertyModule) {
            _.remove(this.subRouts, (sr) => {
                return sr.index === 2;
            });
        }
        if (!this._crmMenuService.isCommercialModule) {
            _.remove(this.subRouts, (sr) => {
                return sr.index === 5;
            });
        }
        if (!this._crmMenuService.isMooringModule) {
            _.remove(this.subRouts, (sr) => {
                return sr.index === 3;
            });
        }
        if (!this._crmMenuService.isBoatModule) {
            _.remove(this.subRouts, (sr) => {
                return sr.index === 4;
            });
        }
        this.viewRecord = false;
        let officeRecord = false;
        if (_.includes(this.router.url, 'view')) {
            this.viewRecord = true;
            officeRecord = true;
        }
        if (this._globalFunction.getUserRole(this.currentUser.user_role)) {
            officeRecord = true;
        }
        if (!this?.currentUser?.user_master_office) {
            officeRecord = true;
        }

        this.form = this._formBuilder.group({
            _id: [],
            client_id: { value: '', disabled: true },
            type: { value: 'individual', disabled: this.viewRecord },
            classification: [{ value: '', disabled: this.viewRecord }],
            dp: { value: '', disabled: this.viewRecord },
            originated_by: { value: '', disabled: this.viewRecord },
            title: { value: '', disabled: this.viewRecord },
            forename: { value: '', disabled: this.viewRecord },
            surname: { value: '', disabled: this.viewRecord },
            gender: { value: '', disabled: this.viewRecord },
            date_of_birth: { value: '', disabled: this.viewRecord },
            birthday_greetings: {value: '', disabled: this.viewRecord},
            nationality: { value: '', disabled: this.viewRecord },
            id_type: { value: '', disabled: this.viewRecord },
            linked_users: { value: '', disabled: this.viewRecord },
            id_number: { value: '', disabled: this.viewRecord },
            id_type_two: { value: '', disabled: this.viewRecord },
            id_number_two: { value: '', disabled: this.viewRecord },
            id_expiration: { value: '', disabled: this.viewRecord },
            partners: this._formBuilder.array([this.addPartners()]),
            company_name: [{ value: '', disabled: this.viewRecord }, Validators.required],
            legal_form: { value: '', disabled: this.viewRecord },
            tax_id: { value: '', disabled: this.viewRecord },
            chamber_number: { value: '', disabled: this.viewRecord },
            language: { value: [], disabled: this.viewRecord },
            home_phone: { value: '', disabled: this.viewRecord },
            company_contact: { value: '', disabled: this.viewRecord },
            work_phone: { value: '', disabled: this.viewRecord },
            mobile_phone: { value: '', disabled: this.viewRecord },
            secondary_mobile_phone: { value: '', disabled: this.viewRecord },
            secondary_work_phone: { value: '', disabled: this.viewRecord },
            secondary_home_phone: { value: '', disabled: this.viewRecord },
            email: { value: '', disabled: this.viewRecord },
            skype_id: { value: '', disabled: this.viewRecord },
            social_media_link: { value: '', disabled: this.viewRecord },
            last_time_contacted: { value: '', disabled: this.viewRecord },
            secondary_email: { value: '', disabled: this.viewRecord },
            communication_language: { value: '', disabled: this.viewRecord },
            address: { value: '', disabled: this.viewRecord },
            country: { value: '', disabled: this.viewRecord },
            region: { value: '', disabled: this.viewRecord },
            province: { value: '', disabled: this.viewRecord },
            city: { value: '', disabled: this.viewRecord },
            postal_code: { value: '', disabled: this.viewRecord },
            street: { value: '', disabled: this.viewRecord },
            street_number: { value: '', disabled: this.viewRecord },
            suffix_apartment: { value: '', disabled: this.viewRecord },
            // address: this._formBuilder.group({
            //   comments: { value: '', disabled: this.viewRecord },
            // }),
            transaction_types: { value: '', disabled: this.viewRecord },
            purpose: { value: '', disabled: this.viewRecord },
            property_types: { value: '', disabled: this.viewRecord },
            sub_types: { value: '', disabled: this.viewRecord },
            min_beds: { value: '', disabled: this.viewRecord },
            min_baths: { value: '', disabled: this.viewRecord },
            countries: { value: '', disabled: this.viewRecord },
            regions: { value: '', disabled: this.viewRecord },
            provinces: { value: '', disabled: this.viewRecord },
            cities: { value: '', disabled: this.viewRecord },
            locations_group: { value: '', disabled: this.viewRecord },
            locations: { value: '', disabled: this.viewRecord },
            urbanization: { value: '', disabled: this.viewRecord },
            urbanization_type: { value: '', disabled: this.viewRecord },
            feet_categories: { value: '', disabled: this.viewRecord },
            custom_categories: { value: '', disabled: this.viewRecord },
            year_built_from: { value: '', disabled: this.viewRecord },
            year_built_to: { value: '', disabled: this.viewRecord },
            min_plot_size: { value: '', disabled: this.viewRecord },
            max_plot_size: { value: '', disabled: this.viewRecord },
            occupancy_status: { value: '', disabled: this.viewRecord },
            license_first_occuption: { value: '', disabled: this.viewRecord },
            min_sleeps: { value: '', disabled: this.viewRecord },
            max_total_floors: { value: '', disabled: this.viewRecord },
            min_built: { value: '', disabled: this.viewRecord },
            max_built: { value: '', disabled: this.viewRecord },
            usefull_area_from: { value: '', disabled: this.viewRecord },
            usefull_area_to: { value: '', disabled: this.viewRecord },
            building_style: { value: '', disabled: this.viewRecord },
            gated: { value: '', disabled: this.viewRecord },
            lift: { value: '', disabled: this.viewRecord },
            feet_setting: { value: '', disabled: this.viewRecord },
            feet_orientation: { value: '', disabled: this.viewRecord },
            feet_views: { value: '', disabled: this.viewRecord },
            feet_condition: { value: '', disabled: this.viewRecord },
            parking: { value: '', disabled: this.viewRecord },
            pool: { value: '', disabled: this.viewRecord },
            feet_garden: { value: '', disabled: this.viewRecord },
            furniture: { value: '', disabled: this.viewRecord },
            mooring_type: { value: '', disabled: this.viewRecord },
            min_depth: { value: '', disabled: this.viewRecord },
            min_width: { value: '', disabled: this.viewRecord },
            min_length: { value: '', disabled: this.viewRecord },
            filter_projects: { value: '', disabled: this.viewRecord },
            filter_golf: { value: '', disabled: this.viewRecord },
            filter_holiday_homes: { value: '', disabled: this.viewRecord },
            filter_repossession: { value: '', disabled: this.viewRecord },
            filter_investment: { value: '', disabled: this.viewRecord },
            filter_off_plan: { value: '', disabled: this.viewRecord },
            filter_distressed: { value: '', disabled: this.viewRecord },
            only_own: { value: '', disabled: this.viewRecord },
            buy_from_date: { value: '', disabled: this.viewRecord },
            buy_price_low: { value: '', disabled: this.viewRecord },
            buy_price_high: { value: '', disabled: this.viewRecord },
            period: { value: '', disabled: this.viewRecord },
            rent_from_date: { value: '', disabled: this.viewRecord },
            rent_to_date: { value: '', disabled: this.viewRecord },
            short_term_Rent_price_low: { value: '', disabled: this.viewRecord },
            short_term_Rent_price_high: { value: '', disabled: this.viewRecord },
            long_term_rent_from_date: { value: '', disabled: this.viewRecord },
            long_term_Rent_price_low: { value: '', disabled: this.viewRecord },
            long_term_Rent_price_high: { value: '', disabled: this.viewRecord },
            newsletter: { value: '', disabled: this.viewRecord },
            comments: { value: '', disabled: this.viewRecord },
            next_contact_date: { value: '', disabled: this.viewRecord },
            nude_sale_buy_from_date: { value: '', disabled: this.viewRecord },
            nude_sale_buy_price_low: { value: '', disabled: this.viewRecord },
            nude_sale_buy_price_high: { value: '', disabled: this.viewRecord },
            fractional_from_date: { value: '', disabled: this.viewRecord },
            fractional_price_low: { value: '', disabled: this.viewRecord },
            fractional_price_high: { value: '', disabled: this.viewRecord },
            minimum_option_price: { value: '', disabled: this.viewRecord },
            maximum_option_price: { value: '', disabled: this.viewRecord },
            minimum_purchase_price: { value: '', disabled: this.viewRecord },
            maximum_purchase_price: { value: '', disabled: this.viewRecord },
            minimum_long_term_rent_price: { value: '', disabled: this.viewRecord },
            maximum_long_term_rent_price: { value: '', disabled: this.viewRecord },
            commercial_profile: this._formBuilder.group({
                transaction_type: { value: '', disabled: this.viewRecord },
                type_one: { value: '', disabled: this.viewRecord },
                type_two: { value: '', disabled: this.viewRecord },
                type_three: { value: '', disabled: this.viewRecord },
                beds_from: { value: '', disabled: this.viewRecord },
                beds_to: { value: '', disabled: this.viewRecord },
                baths_from: { value: '', disabled: this.viewRecord },
                baths_to: { value: '', disabled: this.viewRecord },
                toilets: { value: '', disabled: this.viewRecord },
                bed_from: { value: '', disabled: this.viewRecord },
                bed_to: { value: '', disabled: this.viewRecord },
                countries: { value: '', disabled: this.viewRecord },
                regions: { value: '', disabled: this.viewRecord },
                provinces: { value: '', disabled: this.viewRecord },
                cities: { value: '', disabled: this.viewRecord },
                locations_group: { value: '', disabled: this.viewRecord },
                locations: { value: '', disabled: this.viewRecord },
                urbanization: { value: '', disabled: this.viewRecord },
                urbanization_type: { value: '', disabled: this.viewRecord },
                accomodation: { value: '', disabled: this.viewRecord },
                custom_categories: { value: '', disabled: this.viewRecord },
                feet_categories: { value: '', disabled: this.viewRecord },
                min_built: { value: '', disabled: this.viewRecord },
                parking: { value: '', disabled: this.viewRecord },
                freehold: { value: '', disabled: this.viewRecord },
                leasehold: { value: '', disabled: this.viewRecord },
                email_alerts: { value: '', disabled: this.viewRecord },
                sales_price_from: { value: '', disabled: this.viewRecord },
                sales_price_to: { value: '', disabled: this.viewRecord },
                only_projects: { value: '', disabled: this.viewRecord },
                only_bank_repossessions: { value: '', disabled: this.viewRecord },
                only_investments: { value: '', disabled: this.viewRecord },
                only_offplan: { value: '', disabled: this.viewRecord },
                only_urgent_sales: { value: '', disabled: this.viewRecord },
                owner: { value: '', disabled: this.viewRecord },
                created_from: { value: '', disabled: this.viewRecord },
                created_to: { value: '', disabled: this.viewRecord },
                buy_from_date: { value: '', disabled: this.viewRecord },
                buy_price_from: { value: '', disabled: this.viewRecord },
                buy_price_to: { value: '', disabled: this.viewRecord },
                // new fields for rent 
                rent_from_date: { value: '', disabled: this.viewRecord },
                rent_to_date: { value: '', disabled: this.viewRecord },
                rent_price_from: { value: '', disabled: this.viewRecord },
                rent_price_to: { value: '', disabled: this.viewRecord },
                sleeps: { value: '', disabled: this.viewRecord },
                auction_from_date: { value: '', disabled: this.viewRecord },                
                auction_to_date: { value: '', disabled: this.viewRecord },                
                min_start_price: { value: '', disabled: this.viewRecord },                
                max_start_price: { value: '', disabled: this.viewRecord },                
                max_option_budget: { value: '', disabled: this.viewRecord },                
                min_validity_period: { value: '', disabled: this.viewRecord },                
                rent_buy_months: { value: '', disabled: this.viewRecord },                
                rent_buy_years: { value: '', disabled: this.viewRecord },                
                max_initial_amount: { value: '', disabled: this.viewRecord },                
                year_built_from: { value: '', disabled: this.viewRecord },                
                year_built_to: { value: '', disabled: this.viewRecord },                
                min_plot_size: { value: '', disabled: this.viewRecord },                
                max_plot_size: { value: '', disabled: this.viewRecord },                
                max_total_floors: { value: '', disabled: this.viewRecord },                                
                max_built: { value: '', disabled: this.viewRecord },                
                usefull_area_from: { value: '', disabled: this.viewRecord },                
                usefull_area_to: { value: '', disabled: this.viewRecord },                
                building_style: { value: '', disabled: this.viewRecord },                
                gated: { value: '', disabled: this.viewRecord },                
                lift: { value: '', disabled: this.viewRecord },                
                feet_setting: { value: '', disabled: this.viewRecord },                
                feet_orientation: { value: '', disabled: this.viewRecord },                
                feet_views: { value: '', disabled: this.viewRecord },                
                feet_condition: { value: '', disabled: this.viewRecord },                
                pool: { value: '', disabled: this.viewRecord },                
                feet_garden: { value: '', disabled: this.viewRecord },                
                furniture: { value: '', disabled: this.viewRecord },                
                climate_control: { value: '', disabled: this.viewRecord },                
                min_single_purchase_price: { value: '', disabled: this.viewRecord },                
                min_yearly_rent: { value: '', disabled: this.viewRecord },                
                lease_from_date: { value: '', disabled: this.viewRecord },
                lease_price_from: { value: '', disabled: this.viewRecord },
                lease_price_to: { value: '', disabled: this.viewRecord },
                newsletter: { value: '', disabled: this.viewRecord },
                comments: { value: '', disabled: this.viewRecord },
                next_contact_date: { value: '', disabled: this.viewRecord },
                polygon: { value: '', disabled: this.viewRecord },
                account_alert_lt_rental: { value: '', disabled: this.viewRecord },
                account_alert: { value: '', disabled: this.viewRecord },
                purpose: { value: '', disabled: this.viewRecord },
                occupancy_status: { value: '', disabled: this.viewRecord },
                license_first_occuption: { value: '', disabled: this.viewRecord },
                period: { value: '', disabled: this.viewRecord },
                // Smart Invest fields
                min_price_built_m2: { value: '', disabled: this.viewRecord },
                max_price_built_m2: { value: '', disabled: this.viewRecord },
                min_price_plot_m2: { value: '', disabled: this.viewRecord },
                max_price_plot_m2: { value: '', disabled: this.viewRecord },
                max_return_yearly_rent: { value: '', disabled: this.viewRecord },
                min_price_with_capex: { value: '', disabled: this.viewRecord },
                max_price_with_capex: { value: '', disabled: this.viewRecord },
                min_price_capex_m2: { value: '', disabled: this.viewRecord },
                max_price_capex_m2: { value: '', disabled: this.viewRecord },
                min_room_price: { value: '', disabled: this.viewRecord },
                max_room_price: { value: '', disabled: this.viewRecord },
                min_actual_yearly_rent: { value: '', disabled: this.viewRecord },
                max_actual_yearly_rent: { value: '', disabled: this.viewRecord },
                min_appraised: { value: '', disabled: this.viewRecord },
                max_appraised: { value: '', disabled: this.viewRecord },
                min_debt: { value: '', disabled: this.viewRecord },
                max_debt: { value: '', disabled: this.viewRecord },
                min_capital_gain: { value: '', disabled: this.viewRecord },
                max_capital_gain: { value: '', disabled: this.viewRecord },
                min_gross_profitability: { value: '', disabled: this.viewRecord },
                max_gross_profitability: { value: '', disabled: this.viewRecord },
                min_net_profitability: { value: '', disabled: this.viewRecord },
                max_net_profitability: { value: '', disabled: this.viewRecord },
                max_parking_spot_price: { value: '', disabled: this.viewRecord },
                min_power: { value: '', disabled: this.viewRecord },
                min_office_rooms: { value: '', disabled: this.viewRecord },
                max_office_rooms: { value: '', disabled: this.viewRecord },
                min_size_above_ground: { value: '', disabled: this.viewRecord },
                max_size_above_ground: { value: '', disabled: this.viewRecord },
                min_size_underground: { value: '', disabled: this.viewRecord },
                max_size_underground: { value: '', disabled: this.viewRecord },
                min_floors_above_ground: { value: '', disabled: this.viewRecord },
                max_floors_above_ground: { value: '', disabled: this.viewRecord },
                min_floors_underground: { value: '', disabled: this.viewRecord },
                max_floors_underground: { value: '', disabled: this.viewRecord },
                min_offices: { value: '', disabled: this.viewRecord },
                max_offices: { value: '', disabled: this.viewRecord },
                min_properties: { value: '', disabled: this.viewRecord },
                max_properties: { value: '', disabled: this.viewRecord },
                min_parking_spaces: { value: '', disabled: this.viewRecord },
                max_parking_spaces: { value: '', disabled: this.viewRecord },
                commercial_premises: { value: '', disabled: this.viewRecord },
                terrace_present: { value: '', disabled: this.viewRecord },
                smoke_exit: { value: '', disabled: this.viewRecord },
                status: { value: '', disabled: this.viewRecord },
                operational_strategy: { value: '', disabled: this.viewRecord },
                license_type: { value: '', disabled: this.viewRecord },
                min_percentage_property: { value: '', disabled: this.viewRecord },
                max_percentage_property: { value: '', disabled: this.viewRecord },
                min_total_floors: { value: '', disabled: this.viewRecord },
                land_uses: { value: '', disabled: this.viewRecord },
                change_of_use: { value: '', disabled: this.viewRecord },
                profile_comments: { value: '', disabled: this.viewRecord }
            }),
            search: { value: '', disabled: this.viewRecord },
            offices: { value: [this.currentOffice._id], disabled: officeRecord },
            assigned_to: { value: [], disabled: this.viewRecord },
            source: { value: '', disabled: this.viewRecord },
            status: { value: '', disabled: this.viewRecord },
            lead_status: { value: '', disabled: this.viewRecord },
            probability: { value: '', disabled: this.viewRecord },
            invoice_series: { value: '', disabled: this.viewRecord },
            linked_company: { value: '', disabled: this.viewRecord },
            gdpr_status: { value: '', disabled: this.viewRecord },
            gdpr_comment: { value: '', disabled: this.viewRecord },
            properties_interested: { value: '', disabled: this.viewRecord },
            commercials_interested: { value: '', disabled: this.viewRecord },
            constructions_interested: { value: '', disabled: this.viewRecord },
            moorings: [{ value: '', disabled: this.viewRecord }],
            boats: [{ value: '', disabled: this.viewRecord }],
            related_companies: this._formBuilder.array([this.addRelatedCompanies()]),
            custom_settings: this._formBuilder.array([this.addCustomSettings()]),
            currency: { value: '', disabled: this.viewRecord },
            cash_buyer: { value: '', disabled: this.viewRecord },
            available_cash: { value: '', disabled: this.viewRecord },
            other_assets: { value: '', disabled: this.viewRecord },
            liabilities: { value: '', disabled: this.viewRecord },
            net_worth: { value: '', disabled: true },
            loan_required: { value: '', disabled: this.viewRecord },
            employment: { value: '', disabled: this.viewRecord },
            gross_monthly_income: { value: '', disabled: this.viewRecord },
            net_monthly_income: { value: '', disabled: this.viewRecord },
            financial_accounts: this._formBuilder.array([]),
            min_single_purchase_price: { value: '', disabled: this.viewRecord },
            max_single_purchase_price: { value: '', disabled: this.viewRecord },
            min_initial_amount: { value: '', disabled: this.viewRecord },
            max_initial_amount: { value: '', disabled: this.viewRecord },
            min_lifetime_monthly_rent: { value: '', disabled: this.viewRecord },
            max_lifetime_monthly_rent: { value: '', disabled: this.viewRecord },
            min_temporary_monthly_rent: { value: '', disabled: this.viewRecord },
            max_temporary_monthly_rent: { value: '', disabled: this.viewRecord },
            account_alert: { value: '', disabled: this.viewRecord },
            account_alert_st_rental: { value: '', disabled: this.viewRecord },
            account_alert_lt_rental: { value: '', disabled: this.viewRecord },
            account_alert_nude_sale: { value: '', disabled: this.viewRecord },
            account_alert_fractional: { value: '', disabled: this.viewRecord },
            created_by: { value: '', disabled: this.viewRecord },
            polygon: { value: '', disabled: this.viewRecord },
            agency: { value: '', disabled: this.viewRecord },
            accounting_code: { value: '', disabled: this.viewRecord },
            payment_method: { value: '', disabled: this.viewRecord },
            mooring_profile: this._formBuilder.group({
                transaction_types: { value: '', disabled: this.viewRecord },
                type_one: { value: '', disabled: this.viewRecord },
                buy_from_date: { value: '', disabled: this.viewRecord },
                buy_price_low: { value: '', disabled: this.viewRecord },
                buy_price_high: { value: '', disabled: this.viewRecord },
                period: { value: '', disabled: this.viewRecord },
                rent_from_date: { value: '', disabled: this.viewRecord },
                rent_to_date: { value: '', disabled: this.viewRecord },
                rent_price_low: { value: '', disabled: this.viewRecord },
                rent_price_high: { value: '', disabled: this.viewRecord },
                min_beam: { value: '', disabled: this.viewRecord },
                min_length: { value: '', disabled: this.viewRecord },
                car_parking: { value: '', disabled: this.viewRecord },
                car_accessible: { value: '', disabled: this.viewRecord },
                countries: { value: '', disabled: this.viewRecord },
                regions: { value: '', disabled: this.viewRecord },
                provinces: { value: '', disabled: this.viewRecord },
                cities: { value: '', disabled: this.viewRecord },
                custom_categories: { value: '', disabled: this.viewRecord },
                comments: { value: '', disabled: this.viewRecord },
                next_contact_date: { value: '', disabled: this.viewRecord },
                polygon: { value: '', disabled: this.viewRecord }
            }),
            boat_profile: this._formBuilder.group({
                transaction_types: { value: '', disabled: this.viewRecord },
                type_one: { value: '', disabled: this.viewRecord },
                type_two: { value: '', disabled: this.viewRecord },
                buy_from_date: { value: '', disabled: this.viewRecord },
                buy_price_low: { value: '', disabled: this.viewRecord },
                buy_price_high: { value: '', disabled: this.viewRecord },
                period: { value: '', disabled: this.viewRecord },
                rent_from_date: { value: '', disabled: this.viewRecord },
                rent_to_date: { value: '', disabled: this.viewRecord },
                rent_price_low: { value: '', disabled: this.viewRecord },
                rent_price_high: { value: '', disabled: this.viewRecord },
                min_cabins: { value: '', disabled: this.viewRecord },
                min_heads: { value: '', disabled: this.viewRecord },
                countries: { value: '', disabled: this.viewRecord },
                regions: { value: '', disabled: this.viewRecord },
                provinces: { value: '', disabled: this.viewRecord },
                cities: { value: '', disabled: this.viewRecord },
                hull_material: { value: '', disabled: this.viewRecord },
                drive_type: { value: '', disabled: this.viewRecord },
                fuel_type: { value: '', disabled: this.viewRecord },
                max_passengers:{ value: '', disabled: this.viewRecord },
                engines: { value: '', disabled: this.viewRecord },
                engine_hours: { value: '', disabled: this.viewRecord },
                maximum_speed: { value: '', disabled: this.viewRecord },
                min_length_overall: { value: '', disabled: this.viewRecord },
                max_length_overall: { value: '', disabled: this.viewRecord },
                min_beam_overall: { value: '', disabled: this.viewRecord },
                max_beam_overall: { value: '', disabled: this.viewRecord },
                custom_categories: { value: '', disabled: this.viewRecord },
                comments: { value: '', disabled: this.viewRecord },
                next_contact_date: { value: '', disabled: this.viewRecord },
                polygon: { value: '', disabled: this.viewRecord }
            }),
        });
        this.partners = this.form.get('partners') as UntypedFormArray;
        this.relatedCompanies = this.form.get('related_companies') as UntypedFormArray;
        this.customSettings = this.form.get('custom_settings') as UntypedFormArray;
        this.financialAccounts = this.form.get('financial_accounts') as UntypedFormArray;
    }

    ngOnInit(): void {
        this.mediaObserver.asObservable()
            .pipe(
                takeUntil(this._unsubscribeAll),
                startWith(''),
                filter((changes: MediaChange[]) => changes.length > 0),
                map((changes: MediaChange[]) => changes[0])
            )
            .subscribe((change: MediaChange) => {
                if (change.mqAlias === 'xs') {
                    this.title = '';
                }
                else {
                    if (this.form.get('forename').value !== '') {
                        this.title = ' (' + this.form.get('forename').value;
                        if (this.form.get('surname').value !== '') {
                            this.title = this.title + '-' + this.form.get('surname').value;
                        }
                        this.title = this.title + ')';
                    }
                }
            });
        this.form.get('agency').setValue(this.currentAgency._id);
        if (this.id) {
            this._accountsService.getById(this.id).subscribe((account: any) => {
                if(account.gdpr_status && account.gdpr_status != ''){
                    account.gdpr_status = account.gdpr_status.toString();
                }
                if (account.assigned_to && !isArray(account.assigned_to)) {
                    account.assigned_to = uniq(toArray(account.assigned_to));
                }
                if(account.commercial_profile?.countries && !isArray(account.commercial_profile?.countries)) {
                    let countries = [];
                    countries.push(account.commercial_profile.countries);
                    account.commercial_profile.countries = countries;
                }
                if(account.commercial_profile?.regions && !isArray(account.commercial_profile?.regions)) {
                    let regions = [];
                    regions.push(account.commercial_profile.regions);
                    account.commercial_profile.regions = regions;
                }
                if(account.commercial_profile?.provinces && !isArray(account.commercial_profile?.provinces)) {
                    let provinces = [];
                    provinces.push(account.commercial_profile.provinces);
                    account.commercial_profile.provinces = provinces;
                }
                if(account.commercial_profile?.cities && !isArray(account.commercial_profile?.cities)) {
                    let cities = [];
                    cities.push(account.commercial_profile.cities);
                    account.commercial_profile.cities = cities;
                }
                if(account?.linked_users_data && !account?.linked_users) {
                    account.linked_users = account.linked_users_data?._id;
                    this.form.get('linked_users').setValue(account.linked_users)
                }
                this.form.patchValue(this._globalFunction.clearObject(account));
                this.CurrentAccount = account;
                if (this.form.get('forename').value !== '') {
                    this.title = this.title + ' (' + this.form.get('forename').value;
                    if (this.form.get('surname').value !== '') {
                        this.title = this.title + '-' + this.form.get('surname').value;
                    }
                    this.title = this.title + ')';
                }
                if (!_.isEmpty(account.partners) && account.partners.length) {
                    this.clearFormArray(this.partners);
                    _.forEach(account.partners, (partner, key) => {
                        this.partners.push(this.addPartners());
                        this.form.get('partners.' + key).patchValue(partner);
                    });
                }
                if (!_.isEmpty(account.related_companies) && account.related_companies.length) {
                    this.clearFormArray(this.relatedCompanies);
                    _.forEach(account.related_companies, (company, key) => {
                        this.relatedCompanies.push(this.addRelatedCompanies());
                        this.form.get('related_companies.' + key).patchValue(company);
                    });
                }
                if (!_.isEmpty(account.custom_settings) && account.custom_settings.length) {
                    this.clearFormArray(this.customSettings);
                    _.forEach(account.custom_settings, (setting, key) => {
                        this.customSettings.push(this.addCustomSettings());
                        this.form.get('custom_settings.' + key).patchValue(setting);
                    });
                }
                
                if (!_.isEmpty(account.financial_accounts) && account.financial_accounts.length) {
                    this.clearFormArray(this.financialAccounts);
                    _.forEach(account.financial_accounts, (setting, key) => {
                        this.financialAccounts.push(this.createFinancialAcc());
                        this.form.get('financial_accounts.' + key).patchValue(setting);
                    });
                }
                if (!_.isEmpty(account.probability) && account.probability.name) {
                    this.form.get('probability').patchValue(account.probability.name);
                }
                if (account.address && account.address.formatted_address) {
                    this.form.get('address').setValue(account.address.formatted_address);
                }
            });
        }

    }

    submit(action): void {
        const formData = _.cloneDeep(this.form.value);
        formData.from_crm = 1;
        if (!_.isEmpty(formData.probability)) {
            const probability = _.find(this.probabilities, { name: formData.probability });
            formData.probability = probability ? probability : {};
        }
        // if location is empty passing all locations GitLab #1373
        if (_.isEmpty(formData.locations) && !_.isEmpty(formData.cities)) {
            this.form.get('locations').setValue(this.profileLocations, { emitEvent: false });
            formData.locations = this.profileLocations;
        }
        if (this.id) {
            this.form.value._id = this.id;
            this._accountsService.update(formData).subscribe((data: any) => {
                this._snackBar.open(this._translateService.instant('Updated successfully'), this._translateService.instant('Close'), {
                    duration: 2000,
                });
                if (action === 'close') {
                    this.router.navigate([this.previousUrl], { queryParams: { previousSearch: true } });
                }
            });
        } else {
            this._accountsService.create(formData).subscribe((data: any) => {
                if (action === 'close') {
                    this.router.navigate([this.previousUrl], { queryParams: { previousSearch: true } });
                } else {
                    this.router.navigate(['/master-data/accounts/update/' + data._id]);
                }
            });
        }
    }


    addRelatedCompanies(): UntypedFormGroup {
        return this._formBuilder.group({
            company: { value: '', disabled: this.viewRecord },
            company_type: { value: '', disabled: this.viewRecord },
            company_comment: { value: '', disabled: this.viewRecord },
            company_name: { value: '', disabled: this.viewRecord }
        });
    }

    addCustomSettings(): UntypedFormGroup {
        return this._formBuilder.group({
            key: { value: '', disabled: this.viewRecord },
            value: { value: '', disabled: this.viewRecord }
        });
    }

    createFinancialAcc(): UntypedFormGroup {
        return this._formBuilder.group({
          account_holder: { value: '', disabled: false },
          iban: { value: '', disabled: false },
          bic_swift: { value: '', disabled: false },
          avg_balance: { value: '', disabled: false },
          account_currency: { value: '', disabled: false },
          key: { value: '', disabled: false },
          value: { value: '', disabled: false },
        });
      }

    addPartners(): UntypedFormGroup {
        return this._formBuilder.group({
            title: { value: '', disabled: false },
            forename: { value: '', disabled: false },
            surname: { value: '', disabled: false },
            gender: { value: '', disabled: false },
            date_of_birth: { value: '', disabled: false },
            nationality: { value: '', disabled: false },
            id_type: { value: '', disabled: false },
            id_number: { value: '', disabled: false },
            id_type_two: { value: '', disabled: false },
            id_number_two: { value: '', disabled: false },
            id_expiration: { value: '', disabled: false },
            phone: { value: '', disabled: false },
            email: { value: '', disabled: false },
            last_time_contacted: { value: '', disabled: false }
        });
    }

    ngAfterViewChecked(): void {
        this.cdref.detectChanges();
    }

    clearFormArray = (formArray: UntypedFormArray) => {
        while (formArray.length !== 0) {
            formArray.removeAt(0);
        }
    }
    openProperties(): void {
        this.rpaId = this.form.value;
        this._fuseSidebarService.getSidebar('properties').toggleOpen();
    }
    openCommercial(): void {
        this.rpcId = this.form.value;
        this._fuseSidebarService.getSidebar('properties').toggleOpen();
    }
    onOpenedChanged(event: any): void {
        if (!event) {
            delete this.rpaId;
            delete this.rpcId;
        }
    }
    createOwner(): void {
        this._accountsService.insertCoustomOwner(this.form.value).subscribe((response: any) => {
            this.router.navigate(['/master-data/owners/update/' + response._id]);
        });
    }

}
