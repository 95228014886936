import { Directive, Input, HostListener, ElementRef, Renderer2 } from '@angular/core';
import { BaseService } from 'app/_helpers/base/base.service';
import { MatDialog } from '@angular/material/dialog';
import { WhatsappMessageComponent } from './whatsapp-message/whatsapp-message.component';
import { AuthenticationService } from 'app/services';


@Directive({
  selector: '[whatsapp]'
})
export class WhatsappDirective {

  @Input() AccountIds = [];
  @Input() owner = false;
  currentAgency: any;
  currentOffice: any;
  currentUser: any;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    public _matDialog: MatDialog,
    public _baseService: BaseService,
    public _authenticationService: AuthenticationService,

  ) { 
    this.renderer.setStyle(this.el.nativeElement, 'cursor', 'pointer');
    this.currentAgency = _authenticationService.currentAgencyValue;
    this.currentOffice = _authenticationService.currentOfficeValue;
    this.currentUser = this._authenticationService.currentUserValue;
  }

  @HostListener('click') onClick(): void {
    const dialogRef = this._matDialog.open(WhatsappMessageComponent, {
        panelClass: 'whatsapp-message-dialog',
        data: { ids: this.AccountIds,owner: this.owner},
    });
    dialogRef.afterClosed().subscribe(response => {
      if(response){
        const postData = {
          account: {
            modelId: this.AccountIds,
            agency: this.currentAgency?._id,
            user_office: this.currentOffice?._id,
            userId: this.currentUser?._id,
            user_data: {
                user_name: this.currentUser?.user_name,
                first_name: this.currentUser?.first_name,
                last_name: this.currentUser?.last_name,
                full_name: this.currentUser?.full_name,
                user_email: this.currentUser?.user_email,
                mobile_phone: this.currentUser?.user_phone
            }
          },
          type: 'template', // message type can be text or template
          message: response
        };
        this._baseService.post('external-service/wa-message-bulk', postData, 'nodejs').subscribe((data: any) => {});
      }
    });
  }

}
