import { CommercialPropertiesService } from 'app/services/commercial-properties.service';
import { Component, OnInit, ViewEncapsulation, Inject, ViewChild, ViewChildren, AfterViewInit } from '@angular/core';
import { GridBoardsService } from 'app/services/grid-boards.service';
import { fuseAnimations } from '@fuse/animations';
import { GlobalFuntions } from 'app/_helpers';
import { MatPaginator } from '@angular/material/paginator';
import { MatTabGroup } from '@angular/material/tabs';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { forEach, isArray, merge } from 'lodash';
import { MeetingCreateModalComponent } from 'app/main/activities/meetings/meeting-create-modal/meeting-create-modal.component';
import { TasksCreateModalComponent } from 'app/main/activities/tasks/tasks-create-modal/tasks-create-modal.component';
import { ViewingToursModalComponent } from 'app/main/activities/viewingTours/viewing-tours-modal/viewing-tours-modal.component';
import { TimeLineDataService } from 'app/components/time-line/time-line-data.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { TasksService, MeetingsService, AuthenticationService } from 'app/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ViewingSheetComponent } from 'app/main/activities/viewingTours/viewing-sheet/viewing-sheet.component';
import { ViewingToursService } from 'app/services/viewing-tours.service';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'activities-dialog',
    templateUrl: './activities-dialog.component.html',
    styleUrls: ['./activities-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class ActivitiesDialogComponent implements OnInit, AfterViewInit {

    @ViewChild(MatPaginator, { static: true }) matPaginator: MatPaginator;
    @ViewChild(MatTabGroup, { static: true }) matTabGroup: MatTabGroup;

    displayedColumns: string[];
    activities: any;
    postData: any;
    rpcId: any;
    rppId: string;

    searchDialogRef: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    constructor(
        public matDialogRef: MatDialogRef<ActivitiesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _matDialog: MatDialog,
        private _timeLineData: TimeLineDataService,
        private _fuseSidebarService: FuseSidebarService,
        private _gridBoardsService: GridBoardsService,
        public _globalFunction: GlobalFuntions,
        private _tasksService: TasksService,
        private _meetingsService: MeetingsService,
        private _snackBar: MatSnackBar,
        private _translateService: TranslateService,
        private _viewingToursService: ViewingToursService,
        private _commercialService: CommercialPropertiesService,
        private _authenticationService: AuthenticationService,

    ) { }

    ngOnInit(): void {
        // console.log(this.data);
        // console.log(this.data.activities_ids);
    }

    ngAfterViewInit(): void {
        this.matTabGroup.animationDone
            .subscribe(() => {
                this.postData = {} as any;
                this.postData.activities_ids = this.data.activities_ids;
                if (this.matTabGroup.selectedIndex === 0) {
                    this.displayedColumns = ['subject', 'status', 'start_date', 'end_date', 'start_time', 'end_time', 'action'];
                    this.postData.type = 'meeting';
                }
                if (this.matTabGroup.selectedIndex === 1) {
                    this.displayedColumns = ['subject', 'status', 'priority', 'assignee_user', 'start_date', 'due_date', 'start_time', 'due_time', 'action'];
                    this.postData.type = 'task';
                }
                if (this.matTabGroup.selectedIndex === 2) {
                    this.displayedColumns = ['account_name', 'date', 'assignee_user', 'Objects/Projects', 'created_by_data.username', 'status', 'action'];
                    this.postData.type = 'viewing';
                    this.postData.uc_viewing = false;
                }
                this.loadActivities(this.postData);
            });
    }

    loadActivities(data: any): void {
        console.log(this.matPaginator.pageIndex);

        this._gridBoardsService.getActivities(data, this.matPaginator.pageIndex + 1)
            .subscribe((activities: any) => {
                this.activities = {};
                this.activities.data = activities.body;
                this.activities.total = activities.headers.get('x-pagination-total-count');
                this.activities.limit = activities.headers.get('x-pagination-per-page');

                if (data.type === 'task') {
                    this.activities.data = forEach(activities.body, (value: any) => {
                        if (value && value.assignee_user && isArray(value.assignee_user)) {
                            value.assignee_user_array = true;
                        }
                    });
                }
            });
    }

    openActivities(event: any, id?: string): void {
        if (event.type === 'meeting') {
            this.searchDialogRef = this._matDialog.open(MeetingCreateModalComponent, {
                panelClass: 'event-form-dialog',
                data: event
            });
        } else if (event.type === 'task') {
            this.searchDialogRef = this._matDialog.open(TasksCreateModalComponent, {
                panelClass: 'event-form-dialog',
                data: event
            });
        } else if (event.type === 'viewing') {
            this.searchDialogRef = this._matDialog.open(ViewingToursModalComponent, {
                panelClass: 'event-form-dialog',
                data: {
                    relatedTo: 'update',
                    _id: id
                }
            });
        }
        this.searchDialogRef.afterClosed().subscribe(result => {
            if (result) {
                // this.mySchedule.next(true);
            }
        });
    }

    onOpenTimeline(event: any): void {
        this._timeLineData.setModelDataValue(event);
        this._timeLineData.setRelatedToValue('activities');
        this._fuseSidebarService.getSidebar('timeline').toggleOpen(1000000);
    }

    ClosedStatus(row: any): void {
        const data = row;
        if (row.status === 'Open') {
            data.status = 'Closed';
            this._tasksService.update(data, data._id).subscribe((res: any) => {
                this._snackBar.open(this._translateService.instant('Status changed to close'), this._translateService.instant('Close'), {
                    duration: 2000,
                });
                this.loadActivities(this.postData);
            });
        }
    }

    changeStatus(activity: any): void {
        activity.status = 'Closed';
        this._meetingsService.update(activity)
            .subscribe((data: any) => {
                this._snackBar.open(this._translateService.instant('Status changed to close'), this._translateService.instant('Close'), {
                    duration: 2000,
                });
                this.loadActivities(this.postData);
            });
    }

    openList(data: any): void {
        this.searchDialogRef = this._matDialog.open(ViewingSheetComponent, {
            panelClass: 'event-form-dialog',
            data: data
        });
        this.searchDialogRef.afterClosed()
            .subscribe((response: any) => {
                if (response) {

                }
            });
    }

    commercialReference(property: any){
        const agency = this._authenticationService.currentAgencyValue;
        let reference = property?.commercial_reference;
        if (agency.pdf_external_ref === 'external_reference' && typeof property.commercial_other_reference !== 'undefined' && property.commercial_other_reference) {
            reference = property.commercial_other_reference;
        }
        if (agency.pdf_external_ref === 'agency_reference' && typeof property.commercial_external_reference !== 'undefined' && property.commercial_external_reference) {
        reference = property.commercial_external_reference;
        }
        return reference;
    }

    deleteData(_id, createdBy): void {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });
        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';
        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._viewingToursService.delete(_id, createdBy)
                    .subscribe((data: any) => {
                        this.loadActivities(this.postData);
                    });
            }
            this.confirmDialogRef = null;
        });
    }

    ChangeStatus(_row, _status): void {
        _row.status = _status;
        this._viewingToursService.update(_row._id, _row)
            .subscribe((data: any) => {
                this.loadActivities(this.postData);
            });
    }

    changePage(event: any): void {
        this.loadActivities(this.postData);
    }

    openCommercial(property): void {
        this._fuseSidebarService.getSidebar('properties-view').toggleOpen();
        this.rpcId = property;
    }
    
    openProject(project): void {
        this._fuseSidebarService.getSidebar('properties-view').toggleOpen();
        this.rppId = project;
    }

    onOpenedChanged(event: any): void {
        if (!event) {
          delete this.rpcId;
          delete this.rppId;
        }
    }
}
