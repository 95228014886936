import {
    Component,
    Input,
    OnInit,
    ViewEncapsulation,
    OnDestroy,
    Output,
    EventEmitter,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
// import * as moment from 'moment';
import moment from 'moment-timezone';
import { Subject } from 'rxjs';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { AccountsService } from 'app/services/accounts.service';
import { TranslateService } from '@ngx-translate/core';
import { QuickEditComponent as AccountQuickEdit } from 'app/main/master-data/accounts/quick-edit/quick-edit.component';
import { QuickEditComponent as OwnerQuickEdit } from 'app/main/master-data/owners/quick-edit/quick-edit.component';
import { FormGroup } from '@angular/forms';
import { MeetingCreateModalComponent } from 'app/main/activities/meetings/meeting-create-modal/meeting-create-modal.component';
import { TasksCreateModalComponent } from 'app/main/activities/tasks/tasks-create-modal/tasks-create-modal.component';
import { ViewingToursModalComponent } from 'app/main/activities/viewingTours/viewing-tours-modal/viewing-tours-modal.component';
import { OwnersService } from 'app/services/owners.service';
import {
    toUpper,
    forEach,
    isEmpty,
    truncate,
    isString,
    uniq,
    isArray,
    indexOf,
} from 'lodash';
import { environment } from 'environments/environment';
import { AccountDeleteComponent } from 'app/components/account-delete/account-delete.component';
import { CommonDialogComponent } from 'app/components/common-dialog/common-dialog.component';
import { GetPropertyReferencePipe } from 'app/pipes/get-property-reference.pipe';
import { GetPropertyPricePipe } from 'app/pipes/get-property-price.pipe';
import { ActivitiesDialogComponent } from '../../../activities-dialog/activities-dialog.component';
import { InactiveConfirmationComponent } from 'app/components/account-delete/inactive-confirmation/inactive-confirmation.component';
import { AuthenticationService } from 'app/services';
import { GetCommercialReferencePipe } from 'app/pipes/get-commercial-reference.pipe';
import { GetCommercialPricePipe } from 'app/pipes/get-commercial-price.pipe';
import { CrmMenuService } from 'app/services/crm-menu.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSliderChange } from '@angular/material/slider';
import { GlobalFuntions } from 'app/_helpers';
import { TimeLineService } from 'app/services/time-line.service';
import { AccountsModalComponent } from 'app/components/sequences/accounts-modal/accounts-modal.component';
import { OwnersModalComponent } from 'app/components/sequences/owners-modal/owners-modal.component';
import { WhatsappMessageComponent } from '../../../../../../directives/whatsapp/whatsapp-message/whatsapp-message.component';
import { BaseService } from 'app/_helpers/base/base.service';

@Component({
    selector: 'scrumboard-board-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        GetPropertyReferencePipe,
        GetPropertyPricePipe,
        GetCommercialReferencePipe,
        GetCommercialPricePipe,
    ],
})
export class ScrumboardBoardCardComponent implements OnInit, OnDestroy {
    @Input() cardData: any;
    _loader: boolean;
    currentLang: string;
    env: {
        production: boolean;
        hmr: boolean;
        yiiUrl: string;
        mailBoxUrl: string;
        apiUrl: string;
        cdnUrl: string;
    };
    descriptionLang: string;
    currentAgency: any;
    currentOffice: any;
    isConstructionModule: any;
    @Input() set loader(loader: boolean) {
        this._loader = loader;
    }
    @Output() selectCard: EventEmitter<any>;
    @Output() changeProbability: EventEmitter<any>;
    @Output() openTimeline: EventEmitter<any>;
    @Output() openProperties: EventEmitter<any>;
    @Output() relatedAccounts: EventEmitter<any>;
    @Output() viewProperty: EventEmitter<any>;
    @Output() cardUpdated: EventEmitter<any>;
    @Output() refreshBoard: EventEmitter<any>;
    @Output() viewCommercial: EventEmitter<any>;
    @Output() viewMooring: EventEmitter<any>;
    @Output() viewBoat: EventEmitter<any>;
    @Output() openCommercials: EventEmitter<any>;
    @Output() openConstruction: EventEmitter<any>;
    @Output() openMoorings: EventEmitter<any>;
    @Output() openBoats: EventEmitter<any>;
    emails: string[];
    public checked: any;
    relatedTo: any;
    searchDialogRef: any;
    private _unsubscribeAll: Subject<any>;
    probability: { colour: string; value: number; name: string };
    user: any;
    isInvoiceModule: any;
    isMooringModule: any;
    isBoatModule: any;

    constructor(
        private _activatedRoute: ActivatedRoute,
        public _fuseSidebarService: FuseSidebarService,
        private _accountsService: AccountsService,
        private _ownersService: OwnersService,
        private _matDialog: MatDialog,
        private _translateService: TranslateService,
        public router: Router,
        private getPropertyReference: GetPropertyReferencePipe,
        private getPropertyPrice: GetPropertyPricePipe,
        private getCommercialReferencePipe: GetCommercialReferencePipe,
        private getCommercialPricePipe: GetCommercialPricePipe,
        private _authenticationService: AuthenticationService,
        public _crmMenuService: CrmMenuService,
        public _globalFuntions: GlobalFuntions,
        private _timeLineService: TimeLineService,
        public _baseService: BaseService,

    ) {
        this.user = this._authenticationService.currentUserValue;
        this.currentAgency = this._authenticationService.currentAgencyValue;
        this.currentOffice = _authenticationService.currentOfficeValue;
        this.currentLang =
            this._translateService.currentLang === 'es'
                ? 'es_AR'
                : this._translateService.currentLang;
        this.descriptionLang = toUpper(this._translateService.currentLang);
        this.probability = {
            name: '0%',
            colour: 'blue',
            value: 0,
        };
        this.openProperties = new EventEmitter();
        this.relatedAccounts = new EventEmitter();
        this.viewProperty = new EventEmitter();
        this.selectCard = new EventEmitter();
        this.changeProbability = new EventEmitter();
        this.openTimeline = new EventEmitter();
        this.cardUpdated = new EventEmitter();
        this.refreshBoard = new EventEmitter();
        this.viewCommercial = new EventEmitter();
        this.viewMooring = new EventEmitter();
        this.viewBoat = new EventEmitter();
        this.openCommercials = new EventEmitter();
        this.openConstruction = new EventEmitter();
        this.openMoorings = new EventEmitter();
        this.openBoats = new EventEmitter();
        this._unsubscribeAll = new Subject();
        this.emails = [];
        this._activatedRoute.data
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((v) => (this.relatedTo = v.relatedTo));
        this.env = environment;
        this.isInvoiceModule = this._crmMenuService.isInvoiceModule;
        this.isMooringModule = this._crmMenuService.isMooringModule;
        this.isBoatModule = this._crmMenuService.isBoatModule;
        this.isConstructionModule = this._crmMenuService.isConstructionModule;
    }

    ngOnInit(): void {
        this._translateService.onLangChange
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.currentLang =
                    this._translateService.currentLang === 'es'
                        ? 'es_AR'
                        : this._translateService.currentLang;
                this.descriptionLang = toUpper(
                    this._translateService.currentLang
                );
            });
        if (
            !isEmpty(this.cardData.assigned_to) &&
            isString(this.cardData.assigned_to)
        ) {
            this.cardData.assigned_to = [this.cardData.assigned_to];
        }
        if (!isEmpty(this.cardData.assigned_to_data)) {
            const assignedToData = [];
            forEach(this.cardData.assigned_to_data, (value) => {
                if (isArray(value)) {
                    forEach(value, (valueNext) => {
                        if (valueNext.agency === this.currentAgency._id) {
                            assignedToData.push(valueNext.full_name);
                        }
                    });
                } else if (value.agency === this.currentAgency._id) {
                    assignedToData.push(value.full_name);
                }
            });
            this.cardData.assigned_to_data = uniq(assignedToData);
        }
        if (
            !isEmpty(this.cardData.probability) &&
            !isString(this.cardData.probability)
        ) {
            this.probability = this.cardData.probability;
        }
        if (this.cardData && this.cardData.email) {
            this.emails.push(this.cardData.email);
        }
        this.cardData?.partners?.forEach((partner) => {
            if (partner.email && indexOf(this.emails, partner.email) < 0) {
                this.emails = [...this.emails, partner.email];
            }
        });
        if (this.cardData && this.cardData.related_companies) {
            this.cardData.company_data = '';
            forEach(this.cardData.related_companies, (value) => {
                if (value.company_name) {
                    this.cardData.company_data =
                        this.cardData.company_data + ', ' + value.company_name;
                }
            });
            if (this.cardData.company_data) {
                this.cardData.company_data = this.cardData.company_data.slice(
                    2
                );
            }
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    onInputProbability(event: MatSliderChange): void {
        if (event.value === 1) {
            this.probability.name = '0-20%';
            this.probability.colour = 'blue';
        }
        if (event.value === 2) {
            this.probability.name = '21-50%';
            this.probability.colour = 'green';
        }
        if (event.value === 3) {
            this.probability.name = '51-70%';
            this.probability.colour = 'yellow';
        }
        if (event.value === 4) {
            this.probability.name = '71-99%';
            this.probability.colour = 'red';
        }
        if (event.value === 5) {
            this.probability.name = '100%';
            this.probability.colour = 'white';
        }
    }

    updateData(data: any, tab: string): void {
        if (this.relatedTo === 'account') {
            if (tab === 'tab-profile') {
                data.min_beds = data?.min_beds?.toString() ?? '';
                data.min_baths = data?.min_baths?.toString() ?? '';
            }
            const dialogRef = this._matDialog.open(AccountQuickEdit, {
                disableClose: false,
                panelClass: 'event-form-dialog',
                data: {
                    tab: tab,
                    account: data,
                },
            });
            dialogRef.afterClosed().subscribe((response) => {
                if (!response) {
                    return;
                }
                this._loader = true;
                this.cardUpdated.next(true);
            });
        }
        if (this.relatedTo === 'owner') {
            const dialogRef = this._matDialog.open(OwnerQuickEdit, {
                disableClose: false,
                panelClass: 'event-form-dialog',
                data: {
                    tab: tab,
                    owner: data,
                },
            });
            dialogRef.afterClosed().subscribe((response) => {
                if (!response) {
                    return;
                }
                this._loader = true;
                this.cardUpdated.next(true);
            });
        }
    }

    deleteCard(data: any): void {
        const dialog = this._matDialog.open(AccountDeleteComponent, {
            disableClose: true,
        });
        dialog.afterClosed().subscribe((result) => {
            if (result.inactive) {
                this.inactiveCard(data);
            }
            if (result.delete) {
                if (this.relatedTo === 'account') {
                    this._accountsService
                        .archiveAccount(data._id)
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe(() => {
                            this.cardUpdated.next(true);
                        });
                }
                if (this.relatedTo === 'owner') {
                    data.delete_flag = true;
                    this._ownersService
                        .update(data)
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe(() => {
                            this.cardUpdated.next(true);
                        });
                }
            }
        });
    }

    inactiveCard(data: any): void {
        const dialog = this._matDialog.open(InactiveConfirmationComponent, {
            disableClose: true,
        });
        dialog.afterClosed().subscribe((result) => {
            if (result) {
                data.status = 'inactive';
                data.inactive_message = result;
                if (this.relatedTo === 'account') {
                    this._accountsService
                        .update(data)
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe(() => {
                            this.cardUpdated.next(true);
                        });
                }
                if (this.relatedTo === 'owner') {
                    this._ownersService
                        .update(data)
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe(() => {
                            this.cardUpdated.next(true);
                        });
                }
            }
        });
    }

    addActivities(activity: any, _id?: string, name?: string, assigned_to?: any, email?: string, mobile_phone?: string, source?: string): void {
        let data = {};
        if (this.relatedTo === 'account') {
            data = {
                relatedTo: 'accounts',
                accountId: _id,
                accountName: name,
                assigned_to: assigned_to,
                mobile_phone: mobile_phone,
                email: email,
                source: source,
            };
        }
        if (this.relatedTo === 'owner') {
            data = {
                relatedTo: 'owners',
                ownerId: _id,
                ownerName: name,
            };
        }

        if (activity === 'Meeting') {
            this.searchDialogRef = this._matDialog.open(
                MeetingCreateModalComponent,
                {
                    panelClass: 'event-form-dialog',
                    data: data,
                }
            );
        } else if (activity === 'Task') {
            this.searchDialogRef = this._matDialog.open(
                TasksCreateModalComponent,
                {
                    panelClass: 'event-form-dialog',
                    data: data,
                }
            );
        } else if (activity === 'Viewing') {
            this.searchDialogRef = this._matDialog.open(
                ViewingToursModalComponent,
                {
                    panelClass: 'event-form-dialog',
                    data: data,
                }
            );
        } else if (activity === 'Offer') {
            this.router.navigate(['/activities/offers/create'], {
                state: { data: data },
            });
        } else if (activity === 'Sale') {
            this.router.navigate(['/activities/sales/pre-create'], {
                state: { data: data },
            });
        }

        if (activity !== 'Sale' && activity !== 'Offer') {
            this.searchDialogRef
                .afterClosed()
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((response: any) => {
                    if (response) {
                        this._loader = true;
                        this.refreshBoard.next(true);
                        // this.filter = pickBy(response.value, identity);
                        // this.value.setValue(null);
                    }
                });
        }
    }

    getTooltipHtmlGroup(data: any): any {
        let html = data.type
            ? this._translateService.instant(data.type)
            : this._translateService.instant('N/A');
        if (data.type === 'family' && data.partners && data.partners.length) {
            html = '';
            forEach(data.partners, (partner) => {
                html += `${partner.forename} ${partner.surname} <br>`;
            });
        }
        return html;
    }

    openCommonDialog(data: any, type: string): any {
        if (
            (data.work_phone && data.work_phone !== '') ||
            (data.home_phone && data.home_phone !== '') ||
            (data.mobile_phone && data.mobile_phone !== '') ||
            (data.mobile && data.mobile !== '')
        ) {
            const mobileData = {
                type: this.relatedTo,
                _id: data._id,
                mobile:
                    this.relatedTo === 'account'
                        ? data.mobile_phone
                        : data.mobile,
                homePhone: data.home_phone,
                workPhone: data.work_phone,
            };
            const dialog = this._matDialog.open(CommonDialogComponent, {
                disableClose: false,
                panelClass: 'common-dialog',
                data: mobileData,
            });
            dialog
                .afterClosed()
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((response: any) => {
                    if (response) {
                        data.whatsapp = true;
                        this.openTimeline.next(data);
                    }
                });
        }
    }

    openActivitiesDialog(data: any): any {
        const dialog = this._matDialog.open(ActivitiesDialogComponent, {
            panelClass: 'activities-dialog',
            data: data,
        });
    }

    getTooltipHtmlProperty(property: any): any {
        let title = '';
        let location = '';
        let html = '';
        if (
            !isEmpty(property.title) &&
            !isEmpty(property.title[this.descriptionLang])
        ) {
            title = property.title[this.descriptionLang];
        }
        if (!isEmpty(property.property_location)) {
            if (property.property_location.value) {
                location = property.property_location.value[this.currentLang];
            } else {
                location = property.property_location[this.currentLang];
            }
        }
        html = `<div fxLayout="column" fxLayoutAlign="start stretch">
        <div>${this.getPropertyReference.transform(property)}</div>
        <ng-container *ngIf="${title}">
        <div>${truncate(title, { length: 24 })}</div>
        </ng-container>
        <ng-container *ngIf="${location}">
        <div>${location}</div>
        </ng-container>
        <div>${this.getPropertyPrice.transform(property)}</div>
        </div>`;
        return html;
    }

    getTooltipHtmlCommercial(property: any): any {
        let title = '';
        let location = '';
        let html = '';
        if (
            !isEmpty(property?.title) &&
            !isEmpty(property?.title[this.descriptionLang])
        ) {
            title = property?.title[this.descriptionLang];
        }
        if (!isEmpty(property?.property_location)) {
            if (property?.property_location.value) {
                location = property?.property_location?.value[this.currentLang];
            } else {
                location = property?.property_location[this.currentLang];
            }
        }
        if(property){
            html = `<div fxLayout="column" fxLayoutAlign="start stretch">
            <div>${this.getCommercialReferencePipe.transform(property)}</div>
            <ng-container *ngIf="${title}">
            <div>${truncate(title, { length: 24 })}</div>
            </ng-container>
            <ng-container *ngIf="${location}">
            <div>${location}</div>
            </ng-container>
            <div>${this.getCommercialPricePipe.transform(property)}</div>
            </div>`;
            return html;
        }

    }
    getTooltipHtmlMooring(mooring: any): any {
        let title = '';
        let location = '';
        let html = '';
        if (
            !isEmpty(mooring.title) &&
            !isEmpty(mooring.title[this.descriptionLang])
        ) {
            title = mooring.title[this.descriptionLang];
        }
        if (!isEmpty(mooring.property_location)) {
            if (mooring.property_location.value) {
                location = mooring.property_location.value[this.currentLang];
            } else {
                location = mooring.property_location[this.currentLang];
            }
        }
        html = `<div fxLayout="column" fxLayoutAlign="start stretch">
        <div>${this.getCommercialReferencePipe.transform(mooring)}</div>
        <ng-container *ngIf="${title}">
        <div>${truncate(title, { length: 24 })}</div>
        </ng-container>
        <ng-container *ngIf="${location}">
        <div>${location}</div>
        </ng-container>
        <div>${this.getCommercialPricePipe.transform(mooring)}</div>
        </div>`;
        return html;
    }
    getTooltipHtmlBoat(boat: any): any {
        let title = '';
        let location = '';
        let html = '';
        if (
            !isEmpty(boat.title) &&
            !isEmpty(boat.title[this.descriptionLang])
        ) {
            title = boat.title[this.descriptionLang];
        }
        if (!isEmpty(boat.property_location)) {
            if (boat.property_location.value) {
                location = boat.property_location.value[this.currentLang];
            } else {
                location = boat.property_location[this.currentLang];
            }
        }
        html = `<div fxLayout="column" fxLayoutAlign="start stretch">
        <div>${this.getCommercialReferencePipe.transform(boat)}</div>
        <ng-container *ngIf="${title}">
        <div>${truncate(title, { length: 24 })}</div>
        </ng-container>
        <ng-container *ngIf="${location}">
        <div>${location}</div>
        </ng-container>
        <div>${this.getCommercialPricePipe.transform(boat)}</div>
        </div>`;
        return html;
    }
    getTooltipHtmlPhones(data: any, dialog?: boolean): any {
        let html = '';
        if (data.home_phone && data.home_phone !== '') {
            html += `${this._translateService.instant(
                'home_phone'
            )}: <a href="tel:${data.home_phone}">${data.home_phone}</a> `;
            // whatsapp only need in case of mobile
            // if (dialog) {
            // tslint:disable-next-line: max-line-length
            //     html += `<a href="https://wa.me/?phone=${data.home_phone}&text=" target="_blank" class="font-size-20 green-500-fg"><span class="fab fa-whatsapp"></span></a> <br>`;
            // } else {
            //     html += `<br>`;
            // }
        }
        if (data.work_phone && data.work_phone !== '') {
            html += `${this._translateService.instant(
                'work_phone'
            )}: <a href="tel:${data.work_phone}">${data.work_phone}</a> `;
            // whatsapp only need in case of mobile
            // if (dialog) {
            // tslint:disable-next-line: max-line-length
            //     html += `<a href="https://wa.me/?phone=${data.work_phone}&text=" target="_blank" class="font-size-20 green-500-fg"><span class="fab fa-whatsapp"></span></a> <br>`;
            // } else {
            //     html += `<br>`;
            // }
        }
        if (data.mobile_phone && data.mobile_phone !== '') {
            html += `${this._translateService.instant(
                'mobile_phone'
            )}: <a href="tel:${data.mobile_phone}">${data.mobile_phone}</a> `;
            if (dialog) {
                // tslint:disable-next-line: max-line-length
                html += `<a href="https://wa.me/?phone=${data.mobile_phone}&text=" target="_blank" class="font-size-20 green-500-fg"><span class="fab fa-whatsapp"></span></a> <br>`;
            } else {
                html += `<br>`;
            }
        }
        if (data.mobile && data.mobile !== '') {
            html += `${this._translateService.instant(
                'mobile_phone'
            )}: <a href="tel:${data.mobile}">${data.mobile}</a> `;
        }
        if (!isEmpty(data.partners) && data.partners.length) {
            forEach(data.partners, (partner) => {
                if (!isEmpty(partner.phone)) {
                    html += `${this._translateService.instant(
                        'Partners Phones'
                    )}: <a href="tel:${partner.phone}">${
                        partner.phone
                    }</a> <br>`;
                }
            });
        }
        return html;
    }

    addRemoveToFavorites(data: any): void {
        let modelName = 'accounts';
        if (this.relatedTo === 'owner') {
            modelName = 'owners';
        }
        if (!data.favorites.length) {
            this._accountsService
                .addFavorites(data._id, modelName)
                .subscribe(() => {
                    this.cardUpdated.next(true);
                });
        } else {
            this._accountsService.removeFavorites(data._id).subscribe(() => {
                this.cardUpdated.next(true);
            });
        }
    }
    createOwner(): void {
        this._accountsService
            .insertCoustomOwner(this.cardData)
            .subscribe((response: any) => {
                this.updateAccTimeline();
                this.updateOwnTimeline(response._id);
                this.router.navigate([
                    '/master-data/owners/update/' + response._id,
                ]);
            });
    }
    createAccount(): void {
        this._ownersService
            .insertCoustomAccount(this.cardData)
            .subscribe((response: any) => {
                this.updateAccTimeline(response._id);
                this.updateOwnTimeline();
                this.router.navigate([
                    '/master-data/accounts/update/' + response._id,
                ]);
            });
    }

    updateAccTimeline(id?: any): void {
        const message = {
            message: id
                ? `Converted from Owner by user ${this.user.full_name}`
                : `Converted to Owner by user ${this.user.full_name}`,
            activity_type: '',
            related_to: 'Property',
            related_to_id: '',
            comment: id
                ? `Converted from Owner by user ${this.user.full_name}`
                : `Converted to Owner by user ${this.user.full_name}`,
            created_by: this.user.user_id,
            model: 'Accounts',
            model_id: id ? id : this.cardData._id,
            user: this.user.full_name,
            user_id: this.user.user_id,
        };
        this._timeLineService.addComment(message).subscribe(() => {});
    }

    updateOwnTimeline(id?: any): void {
        const message = {
            message: id
                ? `Converted from Account by user ${this.user.full_name}`
                : `Converted to Account by user ${this.user.full_name}`,
            activity_type: '',
            related_to: 'Property',
            related_to_id: '',
            comment: id
                ? `Converted from Account by user ${this.user.full_name}`
                : `Converted to Account by user ${this.user.full_name}`,
            created_by: this.user.user_id,
            model: 'Owners',
            model_id: id ? id : this.cardData._id,
            user: this.user.full_name,
            user_id: this.user.user_id,
        };
        this._timeLineService.addComment(message).subscribe(() => {});
    }
    openSequence(data?: any, type?: any): any {
        if (type === 'account') {
            this.searchDialogRef = this._matDialog.open(
                AccountsModalComponent,
                {
                    panelClass: 'event-form-dialog',
                    data: data,
                }
            );
        }
        if (type === 'owner') {
            this.searchDialogRef = this._matDialog.open(OwnersModalComponent, {
                panelClass: 'event-form-dialog',
                data: data,
            });
        }
    }

    removeDuplicate(data: any): any {
        const name = [];
        forEach(data, (value) => {
            name.push(value.full_name);
        });
        return uniq(name);
    }

    showWADialog(): boolean {
        let showIcon = false;
        const agencyCustomSettings = this.currentAgency?.custom_settings
        const officeCustomSettings = this.currentOffice?.custom_settings
        let selectedCustomSettings: any[];

        if (
            agencyCustomSettings && 
            Array.isArray(agencyCustomSettings) && 
            agencyCustomSettings.length > 0 && 
            !(this.currentAgency?.whatapp_on_office_level ?? true)
        ) {
            selectedCustomSettings = agencyCustomSettings
        }else {
            selectedCustomSettings = officeCustomSettings
        }

        if(selectedCustomSettings && Array.isArray(selectedCustomSettings) && selectedCustomSettings.length > 0) {
            const accessTokenSetting = selectedCustomSettings.find(setting => setting.key === 'WA_ACCESS_TOKEN');
            const waBusinessAccountId = selectedCustomSettings.find(setting => setting.key === 'WA_BUSINESS_ACCOUNT_ID');
            if (accessTokenSetting && waBusinessAccountId && accessTokenSetting?.value?.trim() !== '' && waBusinessAccountId?.value?.trim() !== '')
                showIcon = true;
        }
        return showIcon;
    }
    openWADialog(accountId: string) {
        const dialogRef = this._matDialog.open(WhatsappMessageComponent, {
            panelClass: 'whatsapp-message-dialog',
            data: { ids: [accountId] },
        });

        dialogRef.afterClosed().subscribe(response => {
            if(response){
                const postData = {
                    account: {
                        modelId: [accountId],
                        agency: this.currentAgency?._id,
                        user_office: this.currentOffice?._id,
                        userId: this.user?._id,
                        user_data: {
                            user_name: this.user?.user_name,
                            first_name: this.user?.first_name,
                            last_name: this.user?.last_name,
                            full_name: this.user?.full_name,
                            user_email: this.user?.user_email,
                            mobile_phone: this.user?.user_phone
                        }
                    },
                    type: 'template', // message type can be text or template
                    message: response
                };
                this._baseService.post('external-service/wa-message-bulk', postData, 'nodejs').subscribe((data: any) => {});
            }
          });

    }
}